::-webkit-scrollbar {
  width: 13px;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    rgba(10, 10, 10, 0) 45%,
    #afafaf 50%,
    transparent 55%,
    hsla(0, 0%, 100%, 0)
  );
  position: relative;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #ccc;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  position: relative;
  left: 2px;
}
